<template>
    <div
        class="relative z-10 grid h-[var(--nav-height)] grid-cols-[1fr_auto_1fr] gap-x-2 px-4 shadow-sm md:gap-x-4 md:px-6"
        :class="[
            dark
                ? 'bg-gray-800 text-white'
                : 'border-b border-gray-200 bg-white',
        ]"
    >
        <!-- Left -->
        <div class="flex h-full items-center justify-start space-x-3">
            <BaseButton
                v-if="appStore.isSidebarAllowed"
                variant="text"
                icon
                class="!-ml-1"
                @click="appStore.toggleSidebar"
            >
                <span class="sr-only">{{ t('toggle-sidebar') }}</span>
                <Bars3BottomLeftIcon class="!h-6 !w-6 text-lg !text-gray-500" />
            </BaseButton>
            <slot name="left" />
        </div>

        <!-- Center -->
        <div class="flex h-full items-center justify-center">
            <slot name="center" />
        </div>

        <!-- Right -->
        <div class="flex h-full items-center justify-end space-x-3">
            <slot name="right" />
            <AppProfileDropdown v-if="authStore.isLoggedIn" class="!-mr-1" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { Bars3BottomLeftIcon } from '@heroicons/vue/24/outline'

defineProps({
    dark: {
        type: Boolean,
        default: false,
    },
})

const { t } = useI18n()
const appStore = useAppStore()
const authStore = useAuthStore()
</script>
